
<template>
  <div>
    <div class="boxContainer">
      <div class="boxGreen">{{$t("status.systemOperational")}}</div>

      <h1 class="current">{{$t("status.currentStatus")}}</h1>
      <div class="statusBox">

        <div class="insideBox">
          <div>
            <h3>{{$t("status.searchEngine")}} <span class="material-icons-outlined help"> help</span></h3>
            <p>{{$t("status.avaible")}}</p>
          </div>
          <span class="material-icons-outlined icon success"> check_circle_outline</span>
        </div>

        <div class="insideBox">
           <div>
                <h3>{{$t("status.profileAnalyzer")}}  <span class="material-icons-outlined help"> help</span></h3>
            <p>{{$t("status.avaible")}}</p>
          </div>
          <span class="material-icons-outlined icon success"> check_circle_outline</span>
        </div>

            <div class="insideBox">
          <div>
            <h3>{{$t("status.lists")}}  <span class="material-icons-outlined help"> help</span></h3>
            <p>{{$t("status.avaible")}}</p>
          </div>
          <span class="material-icons-outlined icon success"> check_circle_outline</span>
        </div>

        <div class="insideBox">
           <div>
                <h3>{{$t("status.monitor")}}  <span class="material-icons-outlined help"> help</span></h3>
            <p>{{$t("status.avaible")}}</p>
          </div>
          <span class="material-icons-outlined icon error"> error_outline</span>
        </div>
        
      </div>

      <hr />
    </div>
  </div>
</template>

<script>
export default { name: "status" };
</script>

<style >
@import '../../assets/css/styles_status.css';
</style>